import React, { useState, useCallback, useEffect } from "react";
import FlagList from "./components/flagList/FlagList";
import styled from "styled-components";
import Amplify, { Auth, API } from "aws-amplify";
import getConfig, { Env } from "./config";

const config = getConfig(process.env.REACT_APP_ENV as Env);

Amplify.configure(config);
API.configure(config);

const Container = styled.div`
  height: 100vh;
  box-sizing: border-box;
  padding-top: 20px;
  padding-left: 15px;
`;

function App() {
  const [signedIn, setSignedIn] = useState(false);
  const [applicationName, setApplicationName] = useState<string>("Agency360");
  const signIn = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (
          user.signInUserSession?.accessToken?.payload &&
          user.signInUserSession.accessToken?.payload["cognito:groups"] &&
          user.signInUserSession.accessToken?.payload[
            "cognito:groups"
          ].includes("Feature-Flag-Admins")
        ) {
          setSignedIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err === "not authenticated" ||
          err === "The user is not authenticated"
        ) {
          if (window.location.search.indexOf("?code=") === -1) {
            sessionStorage.setItem("redirectLocation", window.location.href);
            Auth.federatedSignIn({
              customProvider: "EmpowerId",
            }).catch((_err) => {
              console.error("error signing in", _err);
            });
          }
        }
      });
  }, []);

  useEffect(() => {
    // Detect if the user is landing due to having just authenticated.
    if (!signedIn) {
      signIn();
    }
  }, [signedIn]);

  return (
    <Container className="App">
      <select
        value={applicationName as string}
        onChange={(event: { target: { value: any } }) => {
          setApplicationName(event.target.value);
        }}
      >
        <option value="Agency360">Agency360</option>
        <option value="Customer360">Customer360</option>
      </select>
      <FlagList applicationName={applicationName} />
    </Container>
  );
}

export default App;
