import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import callWithAuth from "../../util/callAPIWithAuth";
import Flag, { flagDetail, permissionType } from "../flag/FeatureFlag";
import getConfig, { Env } from "../../config";

const config = getConfig(process.env.REACT_APP_ENV as Env);

const Container = styled.div`
  max-height: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-top: 15px;
  justify-content: space-between;
  gap: 50px;
  height: 700px;
  width: 100%;

  @media (max-width: 1000px) {
    gap: 0px;
    & > *:first-child {
      overflow-y: scroll;
      max-width: 100%;
    }
    & > *:first-child:not(:last-child) {
      width: 0px;
    }
    & > *:last-child:not(:first-child) {
      overflow-y: scroll;
      width: 100%;
      border-radius: 3px;
      padding-bottom: 30px;
      overflow: scroll;
      border-width: 0px;
    }
  }

  @media (min-width: 1000px) {
    & > *:first-child {
      width: 100%;
      overflow-y: scroll;
    }
    & > *:first-child:not(:last-child) {
      width: 50%;
    }
    & > *:last-child:not(:first-child) {
      float: right;
      width: 50%;
      overflow-y: scroll;
      @keyframes slidein {
        from {
          transform: translateX(100%);
        }

        to {
          transform: translateX(0%);
        }
      }

      animation-duration: 0.3s;
      animation-name: slidein;
    }
  }
`;

const HorizontalBar = styled.div`
  height: 10px;
  background-color: #e2e2e2;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

const CreateFeatureFlagButton = styled.button`
  background-color: #0173c6;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  padding: 10px;
  width: 200px;
  margin-top: 10px;
`;

const Title = styled.span`
  padding: 0px;
  font-size: 25px;
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface selectionButtonProps {
  isSelected: boolean;
}

const SelectionButton = styled.button<selectionButtonProps>`
  background-color: #ffffff;
  border-style: solid;
  border-width: 0px;
  border-color: #0173c6;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  border-radius: 15px;

  color: ${(props) => (props.isSelected ? "#0173c6" : "#000000")};
  font-size: ${(props) => (props.isSelected ? "24px" : "20px")};

  @keyframes slideRight {
    from {
      border-left-width: 0px;
    }

    to {
      border-left-width: 30px;
    }
  }
  transform: ${(props) => (props.isSelected ? "translateX(50px)" : "none")};
  transition: ${(props) => (props.isSelected ? "none" : "transform 250ms")};

  &:hover {
    transform: translateX(50px);
  }
`;

const BackgroundGradient = styled.div`
  background: linear-gradient(to right, #ffffff, #0174c64c);
  width: 55px;
`;

interface listProps {
  applicationName: string;
}

const FlagList = ({ applicationName }: listProps) => {
  const [flags, setFlags] = useState<flagDetail[]>([]);
  const [selectedFlag, setSelectedFlag] = useState<flagDetail | null>(null);

  const closeEditor = () => setSelectedFlag(null);

  const getFlags = useCallback(() => {
    callWithAuth({
      method: "GET",
      url: `${config.api_url}/feature/flags/${applicationName}`,
    })
      .then((res) => {
        setFlags(res.data.Items);
      })
      .catch((err) => console.log("Something went wrong!"));
  }, [applicationName]);

  useEffect(() => {
    closeEditor();
    getFlags();
  }, [applicationName, getFlags]);

  return (
    <Container>
      <Title>
        {applicationName} {config.environment_name} Feature Flags
      </Title>
      <Grid>
        <LeftBox>
          <CreateFeatureFlagButton
            onClick={() =>
              setSelectedFlag({
                flag_name: "",
                application_name: applicationName,
                enabled: false,
                value: {},
                permissions: {
                  type: permissionType.GROUP,
                  value: "",
                },
                tags: [],
              })
            }
          >
            Create Feature Flag
          </CreateFeatureFlagButton>
          <HorizontalBar />
          {flags.map((flag: flagDetail, index: number) => {
            return (
              <BackgroundGradient>
                <SelectionButton
                  isSelected={selectedFlag?.flag_name === flag.flag_name}
                  onClick={() => setSelectedFlag(flags[index])}
                >
                  {flag.flag_name}
                </SelectionButton>
              </BackgroundGradient>
            );
          })}
        </LeftBox>
        {selectedFlag && (
          <Flag
            applicationName={applicationName}
            flagDetails={selectedFlag}
            closeEditor={closeEditor}
            refreshFlags={getFlags}
          />
        )}
      </Grid>
    </Container>
  );
};

export default FlagList;
