import React, { useState, useEffect } from "react";
import styled from "styled-components";
import confetti from "canvas-confetti";
import callWithAuth from "../../util/callAPIWithAuth";
import youKnowWhatItIs from "../../assets/images/you_know_what_it_is.png";
import thoughtful from "../../assets/images/thoughtful.png";
import getConfig, { Env } from "../../config";

const config = getConfig(process.env.REACT_APP_ENV as Env);

let audio = new Audio("/required_sound.mp3");

const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  gap: 5px;
  align-items: center;
`;

const Column = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  gap: 5px;
  align-items: center;
`;

const FieldSet = styled.fieldset`
  box-sizing: border-box;
  border-width: 0px;
  padding: 0px;
  align-items: center;
  & > input:not(:first-child) {
    margin-left: 20px;
  }
`;

const ButtonGroup = styled(Row)`
  box-sizing: border-box;
  align-items: flex-start;
  gap: 15px;
`;

const Button = styled.button`
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  width: 200px;
  height: 50px;
`;

const DeleteButton = styled(Button)`
  background-color: #940000;
  color: #ffffff;
`;

const CancelButton = styled(Button)`
  background-color: #c5c5c5;
`;

const SaveButton = styled(Button)`
  background-color: #0173c6;
  color: #ffffff;
`;

const Container = styled.div`
  box-sizing: border-box;
  border-style: solid;
  border-width: 0px;
  border-left-width: 5px;
  border-color: #e2e2e2;
  padding-left: 10px;
  background-color: #ffffff;
`;

const Text = styled.span`
  font-size: 18px;
`;

const MedGap = styled.div`
  height: 75px;
`;

const ValueRow = styled(Row)`
  gap: 30px;
  @media (max-width: 1000px) {
    gap: 0px;
    flex-direction: column;
  }
`;

const TheImage = styled.img`
  position: absolute;
  transform: rotate(45deg) translateY(100px);
`;

const TheOtherImage = styled.img`
  position: absolute;
  transform: translateX(250px) rotate(-45deg) translateY(-100px);
`;

const ValueItemRow = styled(Row)``;

interface flagProps {
  flagDetails: flagDetail;
  applicationName: string;
  closeEditor: Function;
  refreshFlags: Function;
}

export enum permissionType {
  GROUP = "group",
  USER = "user",
}

export interface flagDetail {
  flag_name: string;
  application_name: string;
  enabled: boolean;
  value: {
    [key: string]: string;
  };
  permissions: {
    type: permissionType;
    value: string;
  };
  tags: string[];
}

const Flag = ({
  flagDetails,
  applicationName,
  closeEditor,
  refreshFlags,
}: flagProps) => {
  const [flagName, setFlagName] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [permissionsType, setPermissionsType] = useState(permissionType.GROUP);
  const [permissionsValue, setPermissionsValue] = useState("");
  const [tags, setTags] = useState("");
  const [valueKeys, setValueKeys] = useState<string[]>([]);
  const [valueValues, setValueValues] = useState<string[]>([]);

  const createRecord = () => {
    if (!flagName.length) {
      window.alert("Please give the flag a unique name!");
      return null;
    }
    let splitTags = tags.split(",");
    splitTags = splitTags.map((untrimmedTag: string) => {
      return untrimmedTag.trim();
    });
    splitTags = splitTags.filter((possiblyEnptyString: string) => {
      return possiblyEnptyString.length;
    });
    let allKeysAreValid = true;
    const value: {
      [key: string]: string;
    } = {};
    for (let i = 0; i < valueKeys.length && allKeysAreValid; i += 1) {
      if (!valueKeys[i].length) {
        allKeysAreValid = false;
      } else {
        value[valueKeys[i]] = valueValues[i];
      }
    }
    if (!allKeysAreValid) {
      window.alert("Value Keys Cannot Be Empty!");
      return null;
    }

    const data: flagDetail = {
      application_name: applicationName,
      flag_name: flagName,
      enabled,
      tags: splitTags,
      permissions: {
        type: permissionsType,
        value: permissionsValue,
      },
      value: value,
    };

    callWithAuth({
      method: "PUT",
      url: `${config.api_url}/feature/flag/create`,
      data,
    })
      .then((res) => {
        closeEditor();
        refreshFlags();
      })
      .catch((err) => console.log("Something went wrong!"));
  };

  const deleteRecord = () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete "${applicationName}-${flagName}"?`
    );
    if (!confirmed) {
      return;
    }
    callWithAuth({
      method: "DELETE",
      url: `${config.api_url}/feature/flag/delete`,
      data: {
        application_name: applicationName,
        flag_name: flagName,
      },
    })
      .then((res) => {
        closeEditor();
        refreshFlags();
      })
      .catch((err) => console.log("Something went wrong!"));
  };

  const updateRecord = () => {
    let splitTags = tags.split(",");
    splitTags = splitTags.map((untrimmedTag: string) => {
      return untrimmedTag.trim();
    });
    splitTags = splitTags.filter((possiblyEnptyString: string) => {
      return possiblyEnptyString.length;
    });
    let allKeysAreValid = true;
    const value: {
      [key: string]: string;
    } = {};
    for (let i = 0; i < valueKeys.length && allKeysAreValid; i += 1) {
      if (!valueKeys[i].length) {
        allKeysAreValid = false;
      } else {
        value[valueKeys[i]] = valueValues[i];
      }
    }
    if (!allKeysAreValid) {
      window.alert("Value Keys Cannot Be Empty!");
      return null;
    }
    const data: flagDetail = {
      application_name: applicationName,
      flag_name: flagName,
      enabled,
      tags: splitTags,
      permissions: {
        type: permissionsType,
        value: permissionsValue,
      },
      value,
    };

    callWithAuth({
      method: "PATCH",
      url: `${config.api_url}/feature/flag/update`,
      data,
    })
      .then((res) => {
        closeEditor();
        refreshFlags();
      })
      .catch((err) => console.log("Something went wrong!"));
  };

  useEffect(() => {
    if (flagName.toLowerCase() === "dan is the best") {
      audio.play();
      confetti({
        particleCount: 125,
        drift: 2,
        startVelocity: 65,
        origin: {
          y: 1,
        },
        spread: 150,
      });
      confetti({
        particleCount: 125,
        drift: -2,
        startVelocity: 65,
        origin: {
          y: 1,
        },
        spread: 150,
      });
    }
  }, [flagName]);

  useEffect(() => {
    setFlagName(flagDetails.flag_name);
    setEnabled(flagDetails.enabled);
    setPermissionsType(flagDetails.permissions.type);
    setPermissionsValue(flagDetails.permissions.value);
    setTags(flagDetails.tags.join(", "));
    const keys = [];
    const values = [];
    for (const [key, value] of Object.entries(flagDetails.value)) {
      keys.push(key);
      values.push(value);
    }
    setValueKeys(keys);
    setValueValues(values);
  }, [flagDetails]);

  let saveButton;
  if (flagDetails.flag_name) {
    saveButton = <SaveButton onClick={updateRecord}>Update</SaveButton>;
  } else {
    saveButton = <SaveButton onClick={createRecord}>Create</SaveButton>;
  }

  return (
    <Container>
      {flagName.toLowerCase() === "dan is the best" && (
        <TheImage src={youKnowWhatItIs} />
      )}
      <Row>
        <Text>Flag Name: </Text>
        {flagDetails.flag_name ? (
          <Text>{flagName}</Text>
        ) : (
          <input
            value={flagName}
            onChange={(event) => {
              setFlagName(event.target.value);
            }}
          />
        )}
        {flagName.toLowerCase() === "dan is the best" && (
          <Text>You know it! {`<(^-^)>`}</Text>
        )}
      </Row>
      <Row>
        <Text>Enabled Status: </Text>
        <FieldSet>
          <input
            checked={enabled}
            type="radio"
            name="enable"
            onChange={(event) => setEnabled(true)}
          />
          <label htmlFor="enable">Enabled</label>
          <input
            checked={!enabled}
            type="radio"
            name="disable"
            onChange={(event) => setEnabled(false)}
          />
          <label htmlFor="disable">Disabled</label>
        </FieldSet>
      </Row>
      <Row>
        <Text>Permission Type: </Text>
        <FieldSet>
          <input
            checked={permissionsType === "group"}
            type="radio"
            name="group"
            onChange={() => setPermissionsType(permissionType.GROUP)}
          />
          <label htmlFor="group">Group</label>
          <input
            checked={permissionsType === "user"}
            type="radio"
            name="user"
            onChange={() => setPermissionsType(permissionType.USER)}
          />
          <label htmlFor="user">User</label>
        </FieldSet>
      </Row>
      <Row>
        <Text>Permission Value: </Text>
        <input
          value={permissionsValue}
          onChange={(event) => setPermissionsValue(event.target.value)}
        />
      </Row>
      <Row>
        <Text>Tags (seperate by commas): </Text>
        <input value={tags} onChange={(event) => setTags(event.target.value)} />
      </Row>
      <ValueRow>
        <Text>Value:</Text>
        <Column>
          <Row>
            <Text>Key</Text>
            <Text>:</Text>
            <Text>Value</Text>
          </Row>
          {valueKeys.map((key: string, index: number) => {
            return (
              <ValueItemRow>
                <input
                  value={key}
                  onChange={(event) => {
                    const tmpKeys = valueKeys.slice();
                    tmpKeys[index] = event.target.value;
                    setValueKeys(tmpKeys);
                  }}
                />
                <input
                  value={valueValues[index]}
                  onChange={(event) => {
                    const tmpValues = valueValues.slice();
                    tmpValues[index] = event.target.value;
                    setValueValues(tmpValues);
                  }}
                />
                <button
                  onClick={() => {
                    const tmpKeysFront = valueKeys.slice(0, index);
                    const tmpKeysBack = valueKeys.slice(index + 1);
                    const tmpValuesFront = valueValues.slice(0, index);
                    const tmpValuesBack = valueValues.slice(index + 1);
                    setValueKeys(tmpKeysFront.concat(tmpKeysBack));
                    setValueValues(tmpValuesFront.concat(tmpValuesBack));
                  }}
                >
                  Remove
                </button>
              </ValueItemRow>
            );
          })}
          <button
            onClick={() => {
              const tmpKeys = valueKeys.slice();
              tmpKeys.push("");
              const tmpValues = valueValues.slice();
              tmpValues.push("");
              setValueKeys(tmpKeys);
              setValueValues(tmpValues);
            }}
          >
            Add
          </button>
        </Column>
      </ValueRow>
      <MedGap />
      <ButtonGroup>
        {saveButton}
        <CancelButton onClick={() => closeEditor()}>Cancel</CancelButton>
        {flagDetails.flag_name ? (
          <DeleteButton onClick={deleteRecord}>Delete</DeleteButton>
        ) : (
          <div />
        )}
      </ButtonGroup>
      {flagName.toLowerCase() === "dan is the best" && (
        <TheOtherImage src={thoughtful} />
      )}
    </Container>
  );
};

export default Flag;
